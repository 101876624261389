import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Line Following Robot",
  "time": "stuff",
  "index": 0,
  "type": "project",
  "hidden": false,
  "splash": "Line following robot with digital and analogue following modes"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Line Following Robot`}</h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2014`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`C`}</inlineCode></p>
    <hr></hr>
    <p>{`This robot was the summative project of a computer hardware course I took in highschool. This robot makes use of PWM (Pulse Width Modulation) to control motor speeds, and transfers the input from each of two IR sensors under the robot to the motor on the opposite side to accomplish line following behavior.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      